<template>
  <div class="">
    <div class="w-full flex text-c16 font-c4 text-left items-center item cursor-pointer">
      <div class="w-1/3 flex items-center">
        <div class="w-6 h-6 bg-yell mr-3 rounded-100" />
        <div>{{ item.lastName }} {{ item.firstName }}</div>
      </div>

      <div class="w-1/3 ml-4 flex items-center">
        {{ item.phone }}
      </div>

      <div class="w-1/3 ml-4">
        <span v-if="item.email">{{ item.email }}</span>
        <span v-if="!item.email"> - </span>
      </div>
    </div>
  </div>
</template>

<script>
import client from '../../../assets/icons/ic-clientAv.svg'

export default {
  name: "Index",
  components: {
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      icons: {
        client,
      },
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.item:hover{
  border-left: 3px groove black;
  padding-left: 30px;
}
</style>
